import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
const PiscineOne = () => {
  return (
    <StaticImage
      src="../../images/piscine1.jpg"
      placeholder="blurred"
      alt="piscine"
      quality="100"
    />
  );
};

export default PiscineOne;
