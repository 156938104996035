import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
const ChambreThree = () => {
  return (
    <StaticImage
      src="../../images/chambre3.jpg"
      placeholder="blurred"
      alt="chambre"
      quality="100"
    />
  );
};

export default ChambreThree;
