import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const SectionService = () => (
  <section id="services" className="bd-service-area pt-125 pb-115">
    <Container>
      <Row>
        <Col lg={4} md={6}>
          <div className="bd-section-title-wrapper mb-50">
            <h5 className="bd-section-subtitle mb-15 subtitle-without-border">
              Les Pins en Ré
            </h5>
            <h2 className="bd-section-title">
              Villa de standing <br />
              <span className="theme-color">Île de Ré</span>
            </h2>
          </div>
        </Col>
        <Col lg={4} md={6}>
          <div className="bd-service bd-service-four mb-30">
            <div className="bd-service-icon mb-20"></div>
            <h4 className="bd-service-title mb-20">Situation</h4>
            <ul>
              <li>
                Construction neuve de style Rétais à Rivedoux Plage, à proximité
                des plages des commerces et pistes cyclables (100m).
              </li>
              <li>
                Quartier calme ou toutes les activités se pratiquent à pieds.
              </li>
            </ul>
          </div>
        </Col>
        <Col lg={4} md={6}>
          <div className="bd-service bd-service-four mb-30">
            <div className="bd-service-icon mb-20"></div>
            <h4 className="bd-service-title mb-20">Intérieur</h4>
            <ul>
              <li>
                Villa de vacances classée 5 étoiles, offrant 2 suites parentales
                avec salles de bains et WC privatifs en rez-de-chaussée et 2
                chambres avec 1 salle de bain et WC à l&apos;étage (vue mer).
              </li>
              <li>
                Grande pièce à vivre (surface 60 m2) avec cuisine-atelier
                ouverte sur salle à manger / salon possédant tous les
                equipements modernes.{' '}
              </li>
              <li>Télévision dans chaque chambre.</li>
            </ul>
          </div>
        </Col>
        <Col lg={4} md={6}>
          <div className="bd-service bd-service-four mb-30">
            <div className="bd-service-icon mb-20"></div>
            <h4 className="bd-service-title mb-20">Extérieur</h4>
            <ul>
              <li>
                La Villa est entièrement ouverte sur un jardin paysagé orienté
                plein sud avec piscine et son volet de protection, son spa et
                ses terrasses avec stores dans un cadre intime et cocooning.
              </li>
              <li>Accès privatif par digicode. </li>
              <li>2 places de parking en accès privé.</li>
            </ul>
          </div>
        </Col>
        <Col lg={4} md={6}>
          <div className="bd-service bd-service-four mb-30">
            <div className="bd-service-icon mb-20"></div>
            <h4 className="bd-service-title mb-20">Informations</h4>
            <ul>
              <li>Villa non-fumeur </li> <li>Animaux admis selon conditions</li>
            </ul>
          </div>
        </Col>
        <Col lg={4} md={6}>
          <div className="bd-service bd-service-four mb-30">
            <div className="bd-service-icon mb-20"></div>
            <h4 className="bd-service-title mb-20">Services Inclus</h4>
            <ul>
              <li>Parking </li>
              <li>
                Le linge de maison pour les lits et pour les salles de bains.
              </li>
              <li>La taxe de séjour n’est pas incluse.</li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default SectionService;
