import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PiscineOne from '../components/images/piscine1';
const SectionHero = () => (
  <section className="bd-slider-area fix mt-80">
    <div className="gray-bg pt-100 pb-100 w-full ">
      <Container>
        <Row className="align-items-center">
          <Col xl={6}>
            <div className="bd-slider-six">
              <h3 className="bd-slider-six-title mb-30">
                Villa de standing <br />
                <span>à louer </span>
              </h3>
              <h4 className="bd-slider-six-title mb-30 ">
                <span>Île de Ré</span>
              </h4>
            </div>
          </Col>
          <Col xl={6}>
            <PiscineOne />
          </Col>
        </Row>
      </Container>
    </div>
  </section>
);

export default SectionHero;
