import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
const ChambreFour = () => {
  return (
    <StaticImage
      src="../../images/chambre4.jpg"
      placeholder="blurred"
      alt="chambre"
      quality="100"
    />
  );
};

export default ChambreFour;
