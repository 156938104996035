import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
const ParentaleOne = () => {
  return (
    <StaticImage
      src="../../images/parentale1.jpg"
      placeholder="blurred"
      alt="chambre parentale"
      quality="100"
    />
  );
};

export default ParentaleOne;