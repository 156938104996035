import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ParentaleOne from './images/parentale1';
import ParentaleTwo from './images/parentale2';
import JardinOne from './images/jardin1';
import JardinTwo from './images/jardin2';
import ChambreThree from './images/chambre3';
import ChambreFour from './images/chambre4';

const SectionPortFolio = () => (
  <section id="portfolio" className="bd-portfolio-area pt-125 pb-145">
    <Container>
      <Row>
        <div className="col-12">
          <div className="bd-section-title-wrapper text-center mb-50">
            <h5 className="bd-section-subtitle mb-15 subtitle-without-border">
              Les pins en Ré
            </h5>
            <h2 className="bd-section-title mb-25">Villa de standing</h2>
            <p className="theme-color">Île de Ré</p>
          </div>
        </div>
      </Row>
      <Row>
        <Col lg="6" md="6">
          <div className="bd-portfolio mb-30">
            <ParentaleOne />
            <div className="bd-portfolio-text">
              <span>RDC</span>
              <h5 className="bd-portfolio-title">Chambre parentale</h5>
            </div>
          </div>
        </Col>
        <Col lg="6" md="6">
          <div className="bd-portfolio mb-30">
            <ParentaleTwo />
            <div className="bd-portfolio-text">
              <span>RDC</span>
              <h5 className="bd-portfolio-title">Chambre parentale</h5>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6">
          <div className="bd-portfolio mb-30">
            <ChambreThree />
            <div className="bd-portfolio-text">
              <span>Etage</span>
              <h5 className="bd-portfolio-title">Chambre 3</h5>
            </div>
          </div>
        </Col>
        <Col lg="6" md="6">
          <div className="bd-portfolio mb-30">
            <ChambreFour />
            <div className="bd-portfolio-text">
              <span>Etage</span>
              <h5 className="bd-portfolio-title">Chambre 4</h5>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6">
          <div className="bd-portfolio mb-30">
            <JardinOne />
            <div className="bd-portfolio-text">
              <span>Extérieur</span>
              <h5 className="bd-portfolio-title">Jardin</h5>
            </div>
          </div>
        </Col>
        <Col lg="6" md="6">
          <div className="bd-portfolio mb-30">
            <JardinTwo />
            <div className="bd-portfolio-text">
              <span>Extérieur Nuit</span>
              <h5 className="bd-portfolio-title">Jardin</h5>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default SectionPortFolio;
