import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
const JardinOne = () => {
  return (
    <StaticImage
      src="../../images/jardin1.jpg"
      placeholder="blurred"
      alt="jardin"
      quality="100"
    />
  )
}

export default JardinOne