import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import Logo from './images/logo';
import { FaPhoneAlt } from '@react-icons/all-files/fa/FaPhoneAlt';
const Header = () => (
  <header>
    <Navbar bg="light" expand="lg" fixed="top" collapseOnSelect>
      <Container>
        <Navbar.Brand href="/">
          <Logo />
          <h2
            style={{ display: 'inline-block', marginTop: '25px' }}
            className="theme-color"
          >
            Les Pins en Ré
          </h2>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="/">Accueil</Nav.Link>
            <Nav.Link href="/#services">Prestations</Nav.Link>
            <Nav.Link href="/#portfolio">Photos</Nav.Link>
            <Nav.Link href="/#footer">Contact</Nav.Link>
          </Nav>
          <Nav>
            <div className="bd-header-contacts">
              <div className="bd-header-contacts-icon">
                <FaPhoneAlt />
              </div>
              <div className="bd-header-contacts-text">
                <span>Renseignements</span>
                <Nav.Link href="tel:+06 12 01 61 96">06 12 01 61 96</Nav.Link>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </header>
);

export default Header;
