import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

const SectionPanoTwo = () => {
  const { image } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "panoramique2.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              quality: 100
            )
          }
        }
      }
    `
  );
  const pluginImage = getImage(image);
  return (
    <BgImage className="bg-fix pt-200 pb-200 mb-100" image={pluginImage} />
  );
};

export default SectionPanoTwo;
