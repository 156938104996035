import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
const Logo = () => {
  return (
    <StaticImage
      src="../../images/logo.png"
      placeholder="blurred"
      alt="jardin"
      width={80}
      height={80}
      quality="100"
    />
  );
};

export default Logo;
