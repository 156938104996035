/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/scss/main.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Layout } from './src/components';

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}> {element} </Layout>;
};

export const onServiceWorkerUpdateReady = () => {
  window.location.reload();
};

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
